<template>
  <main class="flex bg-transparent relative xl:px-6 lg:px-6 md:px-8 px-3">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <section v-if="user" class="w-full">
      <main class="xl:w-3/4 lg:w-3/4 md:w-3/4 w-full pb-16">
        <h1 class="text-xl font-bold">My Profile</h1>
        <section
          class="xl:p-8 lg:p-8 md:p-8 p-4 mt-4 relative"
          style="
            background-color: rgba(253, 79, 0, 0.03);
            border-bottom: 1px solid rgba(253, 79, 0, 0.3);
          "
        >
          <div
            class="
              mt-5
              flex
              xl:flex-row
              lg:flex-row
              md:flex-row
              flex-col
              rounded-t
            "
          >
            <div class="w-32 h-32 border-4 border-primary-color rounded"></div>
            <div class="xl:mt-0 lg:mt-0 md:mt-0 mt-8">
              <div
                class="
                  xl:px-6
                  lg:px-6
                  md:px-6
                  px-0
                  text-sm
                  flex flex-col
                  justify-between
                "
              >
                <div class="flex relative">
                  <div
                    class="rounded-full py-1 px-5 text-primary-color mr-4"
                    style="background-color: rgba(253, 79, 0, 0.1)"
                  >
                    @<span class="username">{{ user.username }}</span>
                  </div>
                  <div
                    class="flex items-center cursor-pointer"
                    @click="copyToClipboard('.username')"
                  >
                    <img src="@/assets/images/copy-icon.svg" alt="user email" />
                    <span class="ml-1 font-light">Copy</span>
                  </div>
                  <div
                    v-if="copyMessage"
                    class="
                      inline-block
                      capitalize
                      text-xs text-legend-gray
                      font-semibold
                      bg-gray-100
                      py-1
                      px-3
                      rounded-full
                      absolute
                      top-0
                      left-0
                    "
                  >
                    copied!
                  </div>
                </div>
                <h1 class="text-lg font-bold my-2 capitalize">
                  {{ user.first_name }} {{ user.last_name }}
                </h1>
                <div class="flex my-1">
                  <img
                    src="@/assets/images/mail-profile.svg"
                    alt="user email"
                  />
                  <span class="ml-2 text-legend-gray dark:text-border-color">{{
                    user.email
                  }}</span>
                </div>
                <div class="flex my-1">
                  <img
                    src="@/assets/images/phone.svg"
                    alt="user phone number"
                  />
                  <div
                    class="
                      ml-2
                      text-legend-gray
                      dark:text-border-color
                      flex
                      items-end
                    "
                  >
                    <span class="font-semibold">{{ user.country_code }}</span
                    ><span>{{ user.phone }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="
              mt-8
              text-sm
              flex
              xl:flex-row
              lg:flex-row
              md:flex-col
              flex-col
            "
          >
            <button
              v-if="!user.is_fibre_customer"
              class="
                border border-primary-color
                rounded
                text-primary-color
                py-2
                xl:px-5 xl:w-auto
                lg:w-auto
                md:w-1/2
                w-3/5
                lg:px-5
                md:px-5
                px-3
                mr-4
                flex
                items-center
              "
              @click="changingPassword = true"
            >
              <img src="@/assets/images/key-icon.svg" alt="user phone number" />
              <span class="ml-2">Change Password</span>
            </button>

            <button
              v-if="user.has_pin === true"
              class="
                border border-primary-color
                rounded
                text-primary-color
                py-2
                xl:px-5
                lg:px-5
                md:px-5
                px-3
                mr-4
                flex
                items-center
                xl:w-auto
                lg:w-auto
                md:w-1/2
                w-3/5
                xl:mt-0
                lg:mt-0
                md:mt-3
                mt-3
              "
              @click="changingTransferPin = true"
            >
              <img src="@/assets/images/change-pin.svg" alt="change pin" />
              <span class="ml-2">Change Transaction PIN</span>
            </button>

            <button
              v-if="user.has_pin === false"
              class="
                border border-primary-color
                rounded
                text-primary-color
                py-2
                xl:px-5
                lg:px-5
                md:px-5
                px-3
                mr-4
                flex
                items-center
                xl:w-auto
                lg:w-auto
                md:w-1/2
                w-3/5
                xl:mt-0
                lg:mt-0
                md:mt-3
                mt-3
              "
              @click="settingTransferPin = true"
            >
              <img src="@/assets/images/change-pin.svg" alt="change pin" />
              <span class="ml-2">Setup Transaction PIN</span>
            </button>

            <button
              v-if="user.is_fibre_customer && !user.is_family_wallet"
              class="
                border border-primary-color
                rounded
                text-primary-color
                py-2
                xl:px-5
                lg:px-5
                md:px-5
                px-3
                mr-4
                flex
                items-center
                xl:w-auto
                lg:w-auto
                md:w-1/2
                w-3/5
                xl:mt-0
                lg:mt-0
                md:mt-3
                mt-3
              "
              @click="isActivateFamilyWallet = true"
            >
              <img
                src="@/assets/images/users.svg"
                alt="Activate Family Wallet"
                class="w-4"
              />
              <span class="ml-2">Activate Family Wallet</span>
            </button>

            <button
              class="
                bg-primary-color
                border border-primary-color
                text-white text-sm
                rounded
                py-2
                xl:px-5
                lg:px-5
                md:px-5
                px-3
                flex
                items-center
                xl:w-auto
                lg:w-auto
                md:w-1/2
                w-3/5
                xl:mt-0
                lg:mt-0
                md:mt-3
                mt-3
              "
              @click="updatingProfile = true"
            >
              <img
                src="@/assets/images/edit-profile-icon.svg"
                alt="user phone number"
              />
              <span class="ml-2">Edit Profile</span>
            </button>
          </div>
          <button
            class="
              text-primary-color
              border border-primary-color
              rounded-md
              py-2
              px-6
              capitalize
              flex
              items-center
              absolute
              right-8
              top-8
            "
            @click="logout"
          >
            <img src="@/assets/images/logout-icon.svg" alt="logout" />
            <span class="ml-2">logout</span>
          </button>
        </section>
        <section
          class="
            p-8
            bg-white
            dark:bg-dark-mode-primary dark:text-border-color
            rounded-b
          "
        >
          <div>
            <h1 class="text-xl font-bold">Personal Information</h1>
            <div
              class="
                flex
                xl:flex-row
                lg:flex-row
                md:flex-col
                flex-col
                justify-between
                text-legend-gray
                dark:text-border-color
                text-sm
                my-4
              "
            >
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">Full Name</h1>
                <div class="capitalize">
                  {{ user.first_name }} {{ user.last_name }}
                </div>
              </div>
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">username</h1>
                <div>{{ user.username }}</div>
              </div>
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">Phone number</h1>
                <div>{{ user.country_code }}{{ user.phone }}</div>
              </div>
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">Email address</h1>
                <div>{{ user.email }}</div>
              </div>
            </div>
          </div>
          <div class="mt-16">
            <h1 class="text-xl font-bold">Address Information</h1>
            <div
              class="
                flex
                xl:flex-row
                lg:flex-row
                md:flex-col
                flex-col
                justify-between
                text-legend-gray
                dark:text-border-color
                text-sm
                my-4
                capitalize
              "
            >
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">Street address</h1>
                <div>{{ user.street_number }}, {{ user.street_name }}</div>
              </div>
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">City</h1>
                <div>{{ user.city }}</div>
              </div>
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">state</h1>
                <div>{{ user.state }}</div>
              </div>
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <h1 class="text-xs font-bold my-3 uppercase">country</h1>
                <div>{{ user.country }}</div>
              </div>
            </div>
          </div>
          <div class="mt-16 flex cursor-pointer" @click="verifyKYC">
            <div
              class="
                text-legend-gray
                dark:text-border-color
                text-sm
                my-4
                w-full
              "
            >
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <div class="mb-2">
                  Provide KYC verification to experience more.
                </div>
                <div
                  class="
                    flex
                    items-center
                    justify-between
                    border border-border-color
                    rounded-md
                    py-3
                    px-4
                    xl:w-1/2
                    lg:w-1/2
                    md:w-3/4
                    w-full
                    relative
                  "
                >
                  <div class="flex items-center">
                    <img
                      src="@/assets/images/fingerprint.svg"
                      alt="KYC verification"
                      class="w-7"
                    />
                    <span class="relative ml-4 text-base text-primary-color">
                      KYC Verification
                    </span>
                  </div>
                  <div
                    v-if="user.is_kyc_verified"
                    class="
                      text-primary-green
                      bg-primary-green bg-opacity-30
                      py-1
                      px-3
                      rounded-full
                      flex
                      items-center
                    "
                  >
                    <img
                      src="@/assets/images/check-mark-success.svg"
                      alt="kyc verified"
                      class="w-3"
                    />
                    <span class="font-semibold text-xs ml-3">KYC VERIFIED</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="mt-16">
            <h1 class="text-xl font-bold">Refer a Friend</h1>
            <div
              class="
                text-legend-gray
                dark:text-border-color
                text-sm
                my-4
                flex
                capitalize
              "
            >
              <div class="xl:mb-0 lg:mb-0 md:mb-6 mb-6">
                <div class="mb-2">
                  You’ll get <b> 2 Points </b> when your friend makes their
                  first transaction.
                </div>
                <div
                  class="
                    flex
                    justify-between
                    xl:flex-row
                    lg:flex-row
                    md:flex-col
                    flex-col
                    border border-border-color
                    rounded-md
                    p-2
                  "
                >
                  <div class="relative mr-6">
                    <div class="link pt-2 lowercase">{{ referalLink }}</div>
                    <div
                      v-if="copyMessage"
                      class="
                        inline-block
                        capitalize
                        text-xs text-legend-gray
                        dark:text-border-color
                        font-semibold
                        bg-gray-100
                        py-1
                        px-3
                        rounded-full
                        absolute
                        top-0
                        left-0
                      "
                    >
                      copied!
                    </div>
                  </div>
                  <button
                    class="
                      bg-primary-color
                      border border-primary-color
                      text-white text-sm
                      rounded
                      py-2
                      xl:px-5
                      lg:px-5
                      md:px-5
                      px-3
                      items-center
                      xl:mt-0
                      lg:mt-0
                      md:mt-4
                      mt-4
                    "
                    @click="copyToClipboard()"
                  >
                    Copy Referral Link
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </section>
    <custom-modal v-if="changingTransferPin">
      <ChangeTransferPin
        slot="cart"
        @closeModal="changingTransferPin = false"
        @change-pin="changeTransferPin"
      />
    </custom-modal>
    <custom-modal v-if="settingTransferPin">
      <SetTransferPin
        slot="cart"
        @closeModal="settingTransferPin = false"
        @change-pin="setTransferPin"
      />
    </custom-modal>

    <custom-modal v-if="changingPassword">
      <ChangePassword
        slot="cart"
        @closeModal="changingPassword = false"
        @change-password="changeUserPassword"
      />
    </custom-modal>
    <custom-modal v-if="verifyingKYC">
      <VerifyKYC
        slot="cart"
        @closeModal="verifyingKYC = false"
        @verificationComplete="completeKYCVerification"
      />
    </custom-modal>

    <custom-modal v-if="updatingProfile">
      <UpdateProfile
        slot="cart"
        @closeModal="updatingProfile = false"
        @update-profile="updateProfile"
        :user="user"
        :loading="submitting"
      />
    </custom-modal>
    <custom-modal v-if="verifyingKYCComplete">
      <KycReport
        slot="cart"
        :user="user"
        :loading="submitting"
        @sendAction="verifyingKYCComplete = false"
      />
    </custom-modal>
    <main
      v-if="isActivateFamilyWallet"
      class="
        w-screen
        h-screen
        bg-legend-black
        dark:bg-gray-50 dark:bg-opacity-20
        bg-opacity-70
        fixed
        flex
        items-center
        justify-center
        top-0
        left-0
        z-40
      "
    >
      <section
        class="
          xl:w-1/3
          lg:w-1/2
          md:w-1/2
          w-11/12
          xl:px-14
          lg:px-10
          md:px-8
          px-5
          py-12
          rounded-md
          bg-white
          dark:bg-legend-black
        "
      >
        <p class="text-center">
          Legend Pay App would like your permission to activate Family Wallet.
        </p>
        <p class="my-8 text-xl text-primary-color font-semibold">
          Are You Sure?
        </p>
        <twin-button
          @leftAction="isActivateFamilyWallet = false"
          @rightAction="activateFamilyWallet"
          :loading="submitting"
        >
          <span slot="left-button">No, Cancel</span>
          <span slot="right-button">Yes, Continue</span>
        </twin-button>
      </section>
    </main>
  </main>
</template>

<script>
import KycReport from '@/components/VerifyKycReport.vue'
import ChangePassword from '@/components/ChangePassword.vue'
import UpdateProfile from '@/components/UpdateProfile.vue'
import ChangeTransferPin from '@/components/ChangeTransferPin.vue'
import VerifyKYC from '@/components/VerifyKyc.vue'
import TwinButton from '@/components/TwinButton.vue'
import SetTransferPin from '@/components/SetTransferPin.vue'
import { mapGetters } from 'vuex'
import errorModule from '@/util/error.handle'

export default {
  name: 'EditProfile',
  components: {
    ChangePassword,
    UpdateProfile,
    ChangeTransferPin,
    SetTransferPin,
    VerifyKYC,
    TwinButton,
    KycReport,
  },
  data() {
    return {
      bvn: null,
      verifyingKYC: false,
      verifyingKYCComplete: false,
      changingPassword: false,
      updatingProfile: false,
      changingTransferPin: false,
      settingTransferPin: false,
      notification: null,
      copyMessage: null,
      hasPin: false,
      submitting: false,
      isActivateFamilyWallet: false,
    }
  },
  computed: {
    ...mapGetters(['user']),
    referalLink() {
      return `${window.location.origin}/register?referral_id=${this.user.username}`
    },
  },
  methods: {
    completeKYCVerification() {
      this.verifyingKYC = false
      this.verifyingKYCComplete = true
    },
    verifyKYC() {
      if (this.user.is_kyc_verified) {
        return
      }

      this.verifyingKYC = true
    },
    copyToClipboard() {
      const text = document.querySelector('.link').textContent.trim()
      navigator.clipboard.writeText(text).then(() => {
        this.copyMessage = 'Copied!'
        setTimeout(() => {
          this.copyMessage = null
        }, 2000)
      })
    },
    async updateProfile(payload) {
      try {
        if (this.submitting) {
          return
        }

        this.submitting = true

        const { status, data } = await this.axios.put('/account', payload)

        if (status === 200) {
          this.notification = {
            type: 'success',
            message: data.message,
          }
          this.submitting = false
          this.updatingProfile = false
          this.$store.commit('SET_USER', data.data)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.submitting = false
      }
    },
    async changeUserPassword(payload) {
      try {
        const { status, data } = await this.axios.put('/account/password', {
          current_password: payload.current_password,
          password: payload.password,
          password_confirmation: payload.password_confirmation,
        })

        if (status === 200) {
          this.changingPassword = false
          this.notification = {
            type: 'success',
            message: data.message,
          }
        }
      } catch (error) {
        this.changingPassword = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        if (error.response.status === 422) {
          this.notification = {
            type: 'error',
            message: error.response.data.errors.password[0],
          }
        }
      }
    },
    async setTransferPin(payload) {
      try {
        const { status, data } = await this.axios.post('/account/wallet/pin', {
          pin: payload.pin,
          confirm_pin: payload.pin_confirmation,
        })

        if (status === 201) {
          this.settingTransferPin = false
          this.notification = {
            type: 'success',
            message: data.message,
          }
        }
      } catch (error) {
        this.settingTransferPin = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        if (error.response.status === 422) {
          this.notification = {
            type: 'error',
            message: error.response.data.errors.password[0],
          }
        }
      }
    },
    async changeTransferPin(payload) {
      try {
        const { status, data } = await this.axios.put('/account/wallet/pin', {
          old_pin: payload.current_pin,
          new_pin: payload.new_pin,
          confirm_pin: payload.pin_confirmation,
        })

        if (status === 200) {
          this.changingTransferPin = false
          this.notification = {
            type: 'success',
            message: data.message,
          }
        }
      } catch (error) {
        this.settingTransferPin = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        if (error.response.status === 422) {
          this.notification = {
            type: 'error',
            message: error.response.data.errors.password[0],
          }
        }
      }
    },
    async logout() {
      try {
        const { status } = await this.axios.get('/account/logout')

        if (status === 200) {
          this.$store.dispatch('logOut')
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async activateFamilyWallet() {
      try {
        const { status, data } = await this.axios.post('/families', {
          username: this.user.username,
        })

        if (status === 200) {
          this.$store.commit('UPDATE_FAMILY', data.data.user)
          this.$router.push({ name: 'Family' })
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
  },
}
</script>
