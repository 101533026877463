var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20"},[_c('x-icon',{staticClass:"\n      custom-class\n      mb-8\n      -ml-5\n      -mt-4\n      text-primary-color\n      cursor-pointer\n      sticky\n      top-0\n    ",attrs:{"size":"1.3x"},on:{"click":function($event){return _vm.$emit('closeModal')}}}),_c('h1',{staticClass:"font-bold text-xl text-legend-black dark:text-border-color"},[_vm._v(" Change Transfer Pin ")]),_c('div',{staticClass:"my-3 text-sm mb-8 text-legend-gray dark:text-border-color"},[_vm._v(" Change your transfer pin to be used before transactions can be initiated. ")]),_c('custom-input',{attrs:{"inputData":{
      label: 'Current Pin',
      fieldName: 'current_pin',
      placeHolder: 'Enter current pin',
    },"attribute-type":_vm.attributeType},on:{"input-value":_vm.setValue}},[_c('eye-icon',{staticClass:"\n        custom-class\n        pr-2\n        text-legend-gray\n        dark:text-border-color\n        eye-icon\n      ",attrs:{"slot":"inline-text-right","size":"1.8x"},on:{"click":_vm.toggleVisibility},slot:"inline-text-right"}),(_vm.loading && !_vm.$v.current_pin.required)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Please provide your current pin")])]):_vm._e()],1),_c('custom-input',{attrs:{"inputData":{
      label: 'New Pin',
      fieldName: 'new_pin',
      placeHolder: 'Enter new pin',
    },"attribute-type":_vm.attributeType},on:{"input-value":_vm.setValue}},[_c('eye-icon',{staticClass:"\n        custom-class\n        pr-2\n        text-legend-gray\n        dark:text-border-color\n        eye-icon\n      ",attrs:{"slot":"inline-text-right","size":"1.8x"},on:{"click":_vm.toggleVisibility},slot:"inline-text-right"}),(!_vm.$v.new_pin.required)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Pin is required.")])]):_vm._e(),(!_vm.$v.new_pin.minLength || !_vm.$v.new_pin.maxLength)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Pin must be within 4 - 6 numbers")])]):_vm._e(),(!_vm.$v.new_pin.numeric)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Pin must be only numbers")])]):_vm._e()],1),_c('custom-input',{attrs:{"inputData":{
      label: 'confirm new pin',
      fieldName: 'pin_confirmation',
      placeHolder: 'Confirm new pin',
    },"attribute-type":_vm.attributeType},on:{"input-value":_vm.setValue}},[_c('eye-icon',{staticClass:"\n        custom-class\n        pr-2\n        text-legend-gray\n        dark:text-border-color\n        eye-icon\n      ",attrs:{"slot":"inline-text-right","size":"1.8x"},on:{"click":_vm.toggleVisibility},slot:"inline-text-right"}),(_vm.loading && !_vm.$v.pin_confirmation.sameAsPin)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Pins do not match.")])]):_vm._e()],1),_c('custom-button',{on:{"sendAction":_vm.changePin}},[_c('span',{attrs:{"slot":"button-text"},slot:"button-text"},[_vm._v("Change Transfer Pin")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }