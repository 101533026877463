<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-32">
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Edit Profile
    </h1>
    <div class="my-3 text-sm mb-8 text-legend-gray dark:text-border-color">
      Keep your profile up to date and gain full access to our platform.
    </div>
    <section class="flex justify-between mb-8">
      <div
        class="text-legend-gray dark:text-border-color capitalize w-1/2 mr-6"
      >
        <label class="font-semibold text-sm mb-1">First name</label>
        <input
          v-model="userData.first_name"
          type="text"
          placeHolder="Enter first name"
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
            capitalize
          "
        />
      </div>
      <div class="text-legend-gray dark:text-border-color capitalize w-1/2">
        <label class="font-semibold text-sm mb-1">Last name</label>
        <input
          v-model="userData.last_name"
          type="text"
          placeHolder="Enter last name"
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
            capitalize
          "
        />
      </div>
    </section>
    <div class="text-legend-gray dark:text-border-color mb-8 capitalize w-full">
      <label class="font-semibold text-sm mb-1">Email address</label>
      <input
        v-model="userData.email"
        type="text"
        placeHolder="e.g ayo@legendpay.ng"
        class="
          w-full
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          bg-transparent
          focus:outline-none
        "
      />
    </div>
    <div class="flex items-center">
      <div class="w-1/3 mb-8 relative">
        <div class="w-full relative">
          <label
            class="
              text-legend-gray
              dark:text-border-color
              font-semibold
              block
              leading-relaxed
            "
            >Country code</label
          >
          <div
            class="
              flex
              items-center
              w-full
              border border-border-color
              rounded-lg
              py-3
              px-1
            "
          >
            <img
              :src="selectedCountryFlag"
              alt=""
              class="w-8 rounded border border-gray-100 shadow-md"
            />
            <vue-autosuggest
              v-model="query"
              :suggestions="filteredOptions"
              @click="clickHandler"
              @selected="onSelected"
              :get-suggestion-value="getSuggestionValue"
              :input-props="{
                id: 'autosuggest__input',
                placeholder: '+234',
              }"
            >
              <div
                slot-scope="{ suggestion }"
                style="display: flex; align-items: center"
              >
                <img
                  :style="{
                    display: 'flex',
                    width: '25px',
                    height: '25px',
                    borderRadius: '15px',
                    marginRight: '10px',
                  }"
                  :src="suggestion.item.href.flag"
                />
                <div style="{ display: 'flex', color: 'navyblue'}">
                  {{ suggestion.item.name }}
                </div>
              </div>
            </vue-autosuggest>
          </div>
        </div>
        <img
          src="@/assets/images/down-chevron.svg"
          alt="select country dialling code"
          class="w-3 absolute right-4 top-11"
        />
      </div>
      <div
        class="
          text-legend-gray
          dark:text-border-color
          mb-8
          capitalize
          w-2/3
          ml-6
        "
      >
        <label class="font-semibold text-sm mb-1">phone number</label>
        <input
          v-model="userData.phone"
          type="text"
          placeHolder="e.g +2348000000000"
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
      </div>
    </div>
    <section class="flex items-start">
      <div
        class="
          w-1/5
          flex-shrink-0
          text-legend-gray
          dark:text-border-color
          mb-8
          capitalize
        "
      >
        <label class="font-semibold text-sm mb-1">Street no.</label>
        <input
          v-model="userData.street_number"
          type="number"
          placeHolder="e.g 1"
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
      </div>
      <div
        class="
          w-4/5
          text-legend-gray
          dark:text-border-color
          mb-8
          capitalize
          ml-6
        "
      >
        <label class="font-semibold text-sm mb-1">Street name</label>
        <input
          v-model="userData.street_name"
          type="text"
          placeHolder="Enter street name"
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
        />
      </div>
    </section>
    <section
      v-if="cities.length"
      class="text-legend-gray dark:text-border-color mb-8 capitalize relative"
    >
      <label class="font-semibold text-sm mb-1">City</label>
      <input
        v-model="userData.city"
        type="text"
        readonly
        placeHolder="Select Area / Zone"
        class="
          w-full
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          bg-transparent
          focus:outline-none
        "
        @click="selectCity = !selectCity"
      />
      <chevron-down-icon
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          absolute
          right-2
          top-9
        "
        @click="selectCity = !selectCity"
      ></chevron-down-icon>
      <ul
        v-if="selectCity"
        class="
          border border-gray-200
          shadow-md
          rounded-md
          bg-white
          absolute
          left-0
          top-full
          w-full
          z-50
          max-h-48
          overflow-y-scroll
          overscroll-contain
        "
      >
        <li
          v-for="(city, index) in cities"
          :key="index"
          class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
          @click="assignSelectedCity(city)"
        >
          {{ city }}
        </li>
      </ul>
    </section>
    <section class="flex">
      <div
        class="
          text-legend-gray
          dark:text-border-color
          mb-8
          capitalize
          w-1/2
          mr-6
          relative
        "
      >
        <label class="font-semibold text-sm mb-1">State</label>
        <input
          v-model="userData.state"
          type="text"
          readonly
          placeHolder="Enter your state"
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
          @click="selectState = !selectState"
        />
        <chevron-down-icon
          size="1.8x"
          class="
            custom-class
            pr-2
            text-legend-gray
            dark:text-border-color
            absolute
            right-2
            top-9
          "
          @click="selectState = !selectState"
        ></chevron-down-icon>
        <ul
          v-if="selectState"
          class="
            border border-gray-200
            shadow-md
            rounded-md
            bg-white
            absolute
            left-0
            top-full
            w-full
            z-50
            max-h-48
            overflow-y-scroll
            overscroll-contain
          "
        >
          <li
            v-for="(state, index) in states"
            :key="index"
            class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
            @click="assignSelectedState(state.name)"
          >
            {{ state.name }}
          </li>
        </ul>
      </div>
      <div
        class="
          text-legend-gray
          dark:text-border-color
          mb-8
          capitalize
          w-1/2
          relative
        "
      >
        <label class="font-semibold text-sm mb-1">Country</label>
        <input
          v-model="country"
          type="text"
          placeHolder="Enter your country"
          readonly
          class="
            w-full
            border border-border-color
            rounded-lg
            flex
            items-center
            p-3
            bg-transparent
            focus:outline-none
          "
          @click="selectCountry = !selectCountry"
        />
        <chevron-down-icon
          size="1.8x"
          class="
            custom-class
            pr-2
            text-legend-gray
            dark:text-border-color
            absolute
            right-1
            top-9
          "
          @click="selectCountry = !selectCountry"
        ></chevron-down-icon>
        <ul
          v-if="selectCountry"
          class="
            border border-gray-200
            shadow-md
            rounded-md
            bg-white
            absolute
            left-0
            top-full
            w-full
            z-50
            max-h-40
            overflow-y-scroll
            overscroll-contain
          "
        >
          <li
            v-for="(country, index) in countries"
            :key="index"
            class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
            @click="assignSelectedCountry(country.name)"
          >
            {{ country.name }}
          </li>
        </ul>
      </div>
    </section>
    <custom-button :loading="loading" @sendAction="updateProfile">
      <span slot="button-text">Update Profile</span>
    </custom-button>
  </main>
</template>

<script>
import listOfCountries from '@/util/countries'
import listOfStates from '@/util/listOfStates'
import { VueAutosuggest } from 'vue-autosuggest'
import { XIcon, ChevronDownIcon } from 'vue-feather-icons'
export default {
  name: 'TransferFunds',
  props: {
    user: {
      type: Object,
      default: () => {},
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    XIcon,
    VueAutosuggest,
    ChevronDownIcon,
  },
  data() {
    return {
      defaultCode: '+234',
      selectCountryCode: false,
      query: '',
      selected: '',
      country: 'Nigeria',
      selectedCountryFlag:
        'https://restfulcountries.com/assets/images/flags/Nigeria.png',
      suggestions: listOfCountries,
      userData: this.user,
      cities: [],
      selectCity: false,
      states: listOfStates,
      selectState: false,
      countries: listOfCountries,
      selectCountry: false,
    }
  },
  computed: {
    filteredOptions() {
      return [
        {
          data: this.suggestions.filter((option) => {
            return (
              option.name.toLowerCase().indexOf(this.query.toLowerCase()) > -1
            )
          }),
        },
      ]
    },
  },
  methods: {
    assignSelectedCity(city) {
      this.userData.city = city
      this.selectCity = false
    },
    assignSelectedState(state) {
      this.userData.state = state
      this.selectState = false
    },
    assignSelectedCountry(country) {
      this.userData.country = country
      this.country = country
      this.selectCountry = false
    },
    clickHandler() {
      this.selected = null
    },
    onSelected(item) {
      this.selected = item.item.phone_code
      this.selectedCountryFlag = item.item.href.flag
    },
    getSuggestionValue(suggestion) {
      return suggestion.item.phone_code.charAt(0) === '+'
        ? suggestion.item.phone_code
        : `+${suggestion.item.phone_code}`
    },
    updateProfile() {
      this.$emit('update-profile', {
        phone_number: this.userData.phone,
        first_name: this.userData.first_name,
        last_name: this.userData.last_name,
        street_number: this.userData.street_number,
        street_name: this.userData.street_name,
        city: this.userData.city,
        state: this.userData.state,
        country: this.country,
      })
    },
  },
}
</script>
