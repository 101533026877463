<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20">
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Change Password
    </h1>
    <div class="my-3 text-sm mb-8 text-legend-gray dark:text-border-color">
      Change your password to secure your account.
    </div>
    <custom-input
      :inputData="{
        label: 'Current Password',
        fieldName: 'current_password',
        placeHolder: 'Enter current password',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          eye-icon
        "
        @click="toggleVisibility"
      ></eye-icon>
      <ValidationMessage
        v-if="loading && !$v.current_password.required"
        slot="validation"
      >
        <span slot="message">You must provide your current password</span>
      </ValidationMessage>
    </custom-input>
    <custom-input
      :inputData="{
        label: 'New Password',
        fieldName: 'password',
        placeHolder: 'Enter new password',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          eye-icon
        "
        @click="toggleVisibility"
      ></eye-icon>
      <ValidationMessage
        v-if="loading && !$v.password.required"
        slot="validation"
      >
        <span slot="message">Password is required.</span>
      </ValidationMessage>
      <ValidationMessage v-if="loading && !$v.password.minLength">
        <span slot="message">Password must be atleast 8 characters</span>
      </ValidationMessage>
    </custom-input>
    <custom-input
      :inputData="{
        label: 'confirm new password',
        fieldName: 'password_confirmation',
        placeHolder: 'Cofirm new password',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          eye-icon
        "
        @click="toggleVisibility"
      ></eye-icon>
      <ValidationMessage
        v-if="loading && !$v.password_confirmation.sameAsPassword"
        slot="validation"
      >
        <span slot="message">Passwords do not match.</span>
      </ValidationMessage>
    </custom-input>
    <custom-button @sendAction="changePassword">
      <span slot="button-text">Change Password</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon, EyeIcon } from 'vue-feather-icons'
import { required, minLength, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'TransferFunds',
  components: {
    XIcon,
    EyeIcon,
  },
  data() {
    return {
      current_password: '',
      password: '',
      password_confirmation: '',
      attributeType: 'password',
      loading: false,
    }
  },
  validations() {
    return {
      current_password: { required },
      password: { required, minLength: minLength(8) },
      password_confirmation: {
        sameAsPassword: sameAs('password'),
      },
    }
  },
  methods: {
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    changePassword() {
      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('change-password', {
        current_password: this.current_password,
        password: this.password,
        password_confirmation: this.password_confirmation,
      })
    },
    toggleVisibility() {
      const nodes = document.querySelectorAll('.eye-icon')
      if (this.attributeType === 'password') {
        this.attributeType = 'text'
        nodes.forEach((node) => {
          node.classList.remove('text-legend-gray')
          node.classList.add('text-primary-color')
        })
      } else {
        this.attributeType = 'password'
        nodes.forEach((node) => {
          node.classList.remove('text-primary-color')
          node.classList.add('text-legend-gray')
        })
      }
    },
  },
}
</script>
