var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('main',{staticClass:"h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20"},[_c('x-icon',{staticClass:"\n      custom-class\n      mb-8\n      -ml-5\n      -mt-4\n      text-primary-color\n      cursor-pointer\n      sticky\n      top-0\n    ",attrs:{"size":"1.3x"},on:{"click":function($event){return _vm.$emit('closeModal')}}}),_c('h1',{staticClass:"font-bold text-xl text-legend-black dark:text-border-color"},[_vm._v(" Change Password ")]),_c('div',{staticClass:"my-3 text-sm mb-8 text-legend-gray dark:text-border-color"},[_vm._v(" Change your password to secure your account. ")]),_c('custom-input',{attrs:{"inputData":{
      label: 'Current Password',
      fieldName: 'current_password',
      placeHolder: 'Enter current password',
    },"attribute-type":_vm.attributeType},on:{"input-value":_vm.setValue}},[_c('eye-icon',{staticClass:"\n        custom-class\n        pr-2\n        text-legend-gray\n        dark:text-border-color\n        eye-icon\n      ",attrs:{"slot":"inline-text-right","size":"1.8x"},on:{"click":_vm.toggleVisibility},slot:"inline-text-right"}),(_vm.loading && !_vm.$v.current_password.required)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("You must provide your current password")])]):_vm._e()],1),_c('custom-input',{attrs:{"inputData":{
      label: 'New Password',
      fieldName: 'password',
      placeHolder: 'Enter new password',
    },"attribute-type":_vm.attributeType},on:{"input-value":_vm.setValue}},[_c('eye-icon',{staticClass:"\n        custom-class\n        pr-2\n        text-legend-gray\n        dark:text-border-color\n        eye-icon\n      ",attrs:{"slot":"inline-text-right","size":"1.8x"},on:{"click":_vm.toggleVisibility},slot:"inline-text-right"}),(_vm.loading && !_vm.$v.password.required)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Password is required.")])]):_vm._e(),(_vm.loading && !_vm.$v.password.minLength)?_c('ValidationMessage',[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Password must be atleast 8 characters")])]):_vm._e()],1),_c('custom-input',{attrs:{"inputData":{
      label: 'confirm new password',
      fieldName: 'password_confirmation',
      placeHolder: 'Cofirm new password',
    },"attribute-type":_vm.attributeType},on:{"input-value":_vm.setValue}},[_c('eye-icon',{staticClass:"\n        custom-class\n        pr-2\n        text-legend-gray\n        dark:text-border-color\n        eye-icon\n      ",attrs:{"slot":"inline-text-right","size":"1.8x"},on:{"click":_vm.toggleVisibility},slot:"inline-text-right"}),(_vm.loading && !_vm.$v.password_confirmation.sameAsPassword)?_c('ValidationMessage',{attrs:{"slot":"validation"},slot:"validation"},[_c('span',{attrs:{"slot":"message"},slot:"message"},[_vm._v("Passwords do not match.")])]):_vm._e()],1),_c('custom-button',{on:{"sendAction":_vm.changePassword}},[_c('span',{attrs:{"slot":"button-text"},slot:"button-text"},[_vm._v("Change Password")])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }