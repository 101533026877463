<template>
  <main
    class="
      h-screen
      overflow-y-scroll
      overscroll-y-contain
      px-10
      pb-20
      relative
      grid
      place-content-center
      text-center
    "
  >
    <!-- <img src="@/assets/images/verification-failed.svg" alt="" class="mx-auto" /> -->
    <img
      src="@/assets/images/verification-successful.svg"
      alt=""
      class="mx-auto"
    />
    <!-- <div>
      <h1 class="text-2xl font-bold my-4">Verification Failed!</h1>
      <p class="">
        Sorry, Something is wrong with your verification. If you have any
        questions, please contact
      </p>
      <p class="text-primary-color my-3">experience@legendpay.ng</p>
    </div> -->
    <div>
      <h1 class="text-2xl font-bold my-4">Verification Successful!</h1>
      <p class="">You have successfully verified your account</p>
    </div>
    <app-button @sendAction="$emit('sendAction')">
      <span slot="button-text">Close</span>
    </app-button>
  </main>
</template>

<script>
import AppButton from '@/components/CustomButton.vue'

export default {
  name: 'KYCVerificatioReport',
  components: {
    AppButton,
  },
}
</script>
