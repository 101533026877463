<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-32">
    <NotificationToast
      v-if="notification"
      :notification="notification"
      @resetNotification="notification = null"
    />
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      KYC Verification
    </h1>
    <div class="my-3 text-sm mb-8 text-legend-gray dark:text-border-color">
      Please use a valid ID
    </div>
    <div class="text-legend-gray dark:text-border-color mb-8 relative">
      <label class="font-semibold text-sm mb-1">ID Type</label>
      <input
        v-model="selectedID.name"
        type="text"
        readonly
        placeHolder="Select ID type"
        class="
          w-full
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          bg-transparent
          focus:outline-none
        "
        @click="getIdList"
      />
      <chevron-down-icon
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          absolute
          right-2
          top-9
          cursor-pointer
        "
        @click="getIdList"
      ></chevron-down-icon>
      <ul
        v-if="selectOption"
        class="
          border border-gray-200
          shadow-md
          rounded-md
          bg-transparent
          absolute
          left-0
          top-full
          w-full
          z-50
          max-h-48
          overflow-y-scroll
          overscroll-contain
          bg-white
          dark:bg-legend-gray dark:text-border-color
        "
      >
        <li
          v-if="selectOption && !idList.length"
          class="py-2 px-4 hover:bg-gray-100"
        >
          Loading...
        </li>
        <li
          v-for="idType in idList"
          :key="idType.id"
          class="py-2 px-4 hover:bg-gray-100 cursor-pointer"
          @click="selectID(idType)"
        >
          {{ idType.name }}
        </li>
      </ul>
      <ValidationMessage v-if="loading && !$v.selectedID.name.required">
        <span slot="message">Select ID type</span>
      </ValidationMessage>
    </div>
    <div class="flex-shrink-0 text-legend-gray dark:text-border-color mb-8">
      <label class="font-semibold text-sm mb-1">ID Number</label>
      <input
        v-model="idNumber"
        type="text"
        placeholder="111222333"
        class="
          w-full
          border border-border-color
          rounded-lg
          flex
          items-center
          p-3
          bg-transparent
          focus:outline-none
        "
      />
      <ValidationMessage v-if="loading && !$v.idNumber.required">
        <span slot="message">ID number is required.</span>
      </ValidationMessage>
    </div>
    <div class="text-legend-gray dark:text-border-color mb-8 relative">
      <label class="font-semibold text-sm mb-1">Date of Birth</label>
      <date-picker
        v-model="dob"
        format="YYYY-MM-DD"
        type="date"
        placeholder="Select date of birth"
      ></date-picker>
      <ValidationMessage v-if="loading && !$v.dob.required">
        <span slot="message">Date of birth is required.</span>
      </ValidationMessage>
    </div>
    <custom-button :loading="submitting" @sendAction="verify">
      <span slot="button-text">Submit</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon, ChevronDownIcon } from 'vue-feather-icons'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import errorModule from '@/util/error.handle'
import { required } from 'vuelidate/lib/validators'
import { mapGetters } from 'vuex'

export default {
  name: 'VerifyKYC',
  components: {
    XIcon,
    ChevronDownIcon,
    DatePicker,
  },
  data() {
    return {
      submitting: false,
      loading: false,
      selectOption: false,
      idList: [],
      selectedID: {
        id: null,
        id_type: null,
        is_active: null,
        name: null,
      },
      idNumber: null,
      dob: null,
      notification: null,
    }
  },
  validations() {
    return {
      selectedID: {
        name: { required },
      },
      idNumber: { required },
      dob: { required },
    }
  },
  computed: {
    ...mapGetters(['user']),
  },
  methods: {
    selectID(idType) {
      this.selectOption = false
      this.selectedID = idType
    },
    async getIdList() {
      try {
        this.selectOption = true

        const { status, data } = await this.axios.get('/kyc-types')

        if (status === 200) {
          this.idList = [...data.data]
        }
      } catch (error) {
        this.selectOption = false
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
      }
    },
    async verify() {
      try {
        if (this.submitting) {
          return
        }
        this.loading = true
        this.$v.$touch()
        if (this.$v.$invalid) {
          return
        }
        this.submitting = true

        const { status, data } = await this.axios.post('/kyc', {
          id_type: this.selectedID.id_type,
          id_number: this.idNumber,
          dob: this.dob,
        })

        if (status === 200) {
          this.$store.commit('UPDATE_KYC_STATUS')
          this.$emit('verificationComplete', data.data)
        }
      } catch (error) {
        this.notification = errorModule(
          error,
          this.notification,
          this.$router,
          this.$store
        )
        this.submitting = false
        this.loading = false
      }
    },
  },
}
</script>
