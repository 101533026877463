<template>
  <main class="h-screen overflow-y-scroll overscroll-y-contain px-10 pb-20">
    <x-icon
      size="1.3x"
      class="
        custom-class
        mb-8
        -ml-5
        -mt-4
        text-primary-color
        cursor-pointer
        sticky
        top-0
      "
      @click="$emit('closeModal')"
    ></x-icon>
    <h1 class="font-bold text-xl text-legend-black dark:text-border-color">
      Change Transfer Pin
    </h1>
    <div class="my-3 text-sm mb-8 text-legend-gray dark:text-border-color">
      Change your transfer pin to be used before transactions can be initiated.
    </div>
    <custom-input
      :inputData="{
        label: 'Current Pin',
        fieldName: 'current_pin',
        placeHolder: 'Enter current pin',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          eye-icon
        "
        @click="toggleVisibility"
      ></eye-icon>
      <ValidationMessage
        v-if="loading && !$v.current_pin.required"
        slot="validation"
      >
        <span slot="message">Please provide your current pin</span>
      </ValidationMessage>
    </custom-input>
    <custom-input
      :inputData="{
        label: 'New Pin',
        fieldName: 'new_pin',
        placeHolder: 'Enter new pin',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          eye-icon
        "
        @click="toggleVisibility"
      ></eye-icon>

      <ValidationMessage v-if="!$v.new_pin.required" slot="validation">
        <span slot="message">Pin is required.</span>
      </ValidationMessage>
      <ValidationMessage
        v-if="!$v.new_pin.minLength || !$v.new_pin.maxLength"
        slot="validation"
      >
        <span slot="message">Pin must be within 4 - 6 numbers</span>
      </ValidationMessage>
      <ValidationMessage v-if="!$v.new_pin.numeric" slot="validation">
        <span slot="message">Pin must be only numbers</span>
      </ValidationMessage>
    </custom-input>

    <custom-input
      :inputData="{
        label: 'confirm new pin',
        fieldName: 'pin_confirmation',
        placeHolder: 'Confirm new pin',
      }"
      :attribute-type="attributeType"
      @input-value="setValue"
    >
      <eye-icon
        slot="inline-text-right"
        size="1.8x"
        class="
          custom-class
          pr-2
          text-legend-gray
          dark:text-border-color
          eye-icon
        "
        @click="toggleVisibility"
      ></eye-icon>

      <ValidationMessage
        v-if="loading && !$v.pin_confirmation.sameAsPin"
        slot="validation"
      >
        <span slot="message">Pins do not match.</span>
      </ValidationMessage>
    </custom-input>
    <custom-button @sendAction="changePin">
      <span slot="button-text">Change Transfer Pin</span>
    </custom-button>
  </main>
</template>

<script>
import { XIcon, EyeIcon } from 'vue-feather-icons'
import {
  required,
  minLength,
  maxLength,
  numeric,
  sameAs,
} from 'vuelidate/lib/validators'

export default {
  name: 'ChangeTransferPin',
  components: {
    XIcon,
    EyeIcon,
  },
  data() {
    return {
      current_pin: '',
      new_pin: '',
      pin_confirmation: '',
      attributeType: 'password',
      loading: false,
    }
  },
  validations() {
    return {
      current_pin: { required },
      new_pin: {
        required,
        numeric,
        minLength: minLength(4),
        maxLength: maxLength(6),
      },
      pin_confirmation: {
        sameAsPin: sameAs('new_pin'),
      },
    }
  },
  methods: {
    setValue(value) {
      const currentField = value.field
      this[currentField] = value.value
    },
    changePin() {
      this.loading = true

      this.$v.$touch()

      if (this.$v.$invalid) {
        return
      }

      this.$emit('change-pin', {
        current_pin: this.current_pin,
        new_pin: this.new_pin,
        pin_confirmation: this.pin_confirmation,
      })
    },
    toggleVisibility() {
      const nodes = document.querySelectorAll('.eye-icon')
      if (this.attributeType === 'password') {
        this.attributeType = 'text'
        nodes.forEach((node) => {
          node.classList.remove('text-legend-gray')
          node.classList.add('text-primary-color')
        })
      } else {
        this.attributeType = 'password'
        nodes.forEach((node) => {
          node.classList.remove('text-primary-color')
          node.classList.add('text-legend-gray')
        })
      }
    },
  },
}
</script>
